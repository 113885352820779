import gql from "graphql-tag"
import debounce from "lodash.debounce"
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
    BAlert,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardBody,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BFormGroup, BFormSelect,
    BLink,
    BInputGroupAppend,
    BBadge,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BTooltip
} from 'bootstrap-vue'

const GET_CONTRACTS = gql`
    query getContracts (
        $codcolcfo: Int,
        $codcfo: String,
        $contrato: Int,
        $cliente_nome: String,
        $offset: Int,
        $limit: Int,
        $order_by: [api_view_portal_corretor_contratos_order_by!]
    ) {
        api_view_portal_corretor_contratos(
            where: {
                _and: [
                    {codcolcfo: {_eq: $codcolcfo}},
                    {codcfo: {_eq: $codcfo}},
                    {
                        _or: [
                            {contrato: {_eq: $contrato}},
                            {cliente_nome: {_like: $cliente_nome}}
                        ]
                    }
                ]
            },
            order_by: $order_by,
            offset: $offset,
            limit: $limit
        ) {
            codcfo
            codcolcfo
            numvenda
            contrato
            contrato_situacao
            data_venda
            empreendimento_fantasia
            quadra
            lote
            cliente_nome
            codcoligada
            valor_atraso
            valor_comissao_paga
            valor_comissao_prevista
            valor_comissao_total
            valor_recebido
            valor_venda
        }
        api_view_portal_corretor_contratos_aggregate(
            where: {
                _and: [
                    {codcolcfo: {_eq: $codcolcfo}},,
                    {codcfo: {_eq: $codcfo}},
                    {
                        _or: [
                            {contrato: {_eq: $contrato}},
                            {cliente_nome: {_like: $cliente_nome}}
                        ]
                    }
                ]
            },
        ) {
            aggregate {
                count
            }
        }
    }
`;

const GET_PARCELS = gql`
    query getParcels (
        $codcoligada: Int,
        $numvenda: Int,
        $order_by: [api_view_portal_corretor_contrato_parcelas_order_by!]
    ) {
        api_view_portal_corretor_contrato_parcelas(
            where: {
                _and: [
                    {codcoligada: {_eq: $codcoligada}},
                    {numvenda: {_eq: $numvenda}},
                    {sinal: {_eq: "Sim"}}
                ]
            },
            order_by: $order_by
        ) {
            codcoligada
            numvenda
            idlan
            parcela
            data_vencimento
            data_pagamento
            valor_aberto
            valor_pago
            situacao_desc
        }
    }
`;

export default {
    components: {
        BCardText,
        BCard,
        BSpinner,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        BAlert,
        BTooltip
    },

    data() {
        return {
            contracts : null,
            contract : null,
            parcels : null,
            loading: false,
            loadingContracts: false,
            perPage: 10,
            perPageParcels: 5,
            totalRows: 1,
            totalRows2 : 1,
            currentPage: 1,
            currentPage2: 1,
            pageOptions: [10, 15, 20],
            sortBy: 'data_venda',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },

            // Lista de Contratos
            fields: [
                {
                    key: 'contrato',
                    label: 'Contrato',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'data_venda',
                    label: 'Data',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'cliente_nome',
                    label: 'Cliente',
                    sortable: true
                },
                {
                    key: 'empreendimento_fantasia',
                    label: 'Empreendimento',
                    sortable: true
                },
                {
                    key: 'valor_venda',
                    label: 'Valor',
                    tdClass: 'text-right',
                    thClass: 'text-right',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Financeiro',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: false
                },
            ],

            // Parcelas do Contrato
            fields2: [
                {
                    key: 'parcela',
                    label: 'Parcela',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: false
                },
                {
                    key: 'data_vencimento',
                    label: 'Vencimento',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true,
                    formatter: value => { return this.formatDate(value) }
                },
                {
                    key: 'valor',
                    label: 'Valor',
                    tdClass: 'text-right',
                    thClass: 'text-right',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return this.formatPriceTable(item.valor_aberto + item.valor_pago)
                    }
                },
                {
                    key: 'data_pagamento',
                    label: 'Pagamento',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true,
                    formatter: value => { return this.formatDate(value) }
                },
                {
                    key: 'situacao_desc',
                    label: 'Situação',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: false
                },
            ],
        }
    },

    created() {
      if(this.loggedUser){
        this.getContracts()
      }
    },

    methods: {
        
        async getContracts() {
            this.loadingContracts = true;

            this.$apollo.query({
                query: GET_CONTRACTS,
                variables: {
                    codcolcfo: parseInt(this.loggedUser.codcolcfo),
                    codcfo: this.loggedUser.codcfo,
                    contrato: parseInt(this.filter) || 0,
                    cliente_nome: '%' + (this.filter || '') + '%',
                    offset: (this.perPage * this.currentPage) - this.perPage,
                    limit: this.perPage,
                    order_by: { [this.sortBy || 'data_venda']: this.sortDesc ? 'desc' : 'asc' }
                },
                fetchPolicy: 'cache-first' //'cache-first ou no-cache'
            })

                .then((data) => {
                    this.loadingContracts = false;
                    this.contracts = data['data']['api_view_portal_corretor_contratos']
                    this.totalRows = data["data"]["api_view_portal_corretor_contratos_aggregate"]["aggregate"]["count"] || 0
                })

                .catch(error => {
                    this.loadingContracts = false;
                    this.showToast({
                        title: 'Erro!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: 'Erro ao obter Contratos! ' + error,
                    }, 3000);
                })
        },

        async getParcels(sale) {

            this.contract = sale;
            this.parcels = null;
            this.totalRows2 = 1;
            this.currentPage2 = 1;
            this.loading = true;

            this.$refs['parcelsModal'].show()

            this.$apollo.query({
                query: GET_PARCELS,
                variables: {
                    codcoligada: parseInt(sale.codcoligada),
                    numvenda: parseInt(sale.numvenda),
                    order_by: { data_vencimento: 'asc' }
                },
                fetchPolicy: 'no-cache' // não ativar cache pois data pagamento pode ser nula
            })

                .then((data) => {
                    this.loading = false;
                    this.parcels = data['data']['api_view_portal_corretor_contrato_parcelas']
                    this.totalRows2 = (this.parcels && this.parcels.length) || 0
                })

                .catch(error => {
                    this.loading = false;
                    this.showToast({
                        title: 'Erro!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: 'Erro ao obter Parcelas do Contrato! ' + error,
                    }, 3000);
                })
        },

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            },
                {
                    timeout: duration
                });
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatPriceTable(value) {
            if (value > 0) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            else {
                return null
            }
        },

        formatValue(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(0, 4)
            let month = dateValue.substr(5, 2)
            let day = dateValue.substr(8, 2)
            return day + '/' + month + '/' + year;
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        clearFilter() {
            this.filter = ''
            this.filterContracts()
        },

        filterContracts: debounce(function () {
            this.currentPage = 1
            this.getContracts()
        }, 1000),

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        resolveStatusFinanceiroContrato (item) {
            if (item.valor_atraso <= 0 && item.valor_comissao_prevista <= 0)
                return { variant: 'success', icon: 'CheckCircleIcon', label: 'Quitado' }
            if (item.valor_atraso <= 0 && item.valor_comissao_prevista >= 0)
                return { variant: 'warning', icon: 'CheckCircleIcon', label: 'A Vencer' }
            if (item.valor_atraso > 0)
                return { variant: 'danger', icon: 'InfoIcon', label: 'Em Atraso' }
            return { variant: 'secondary', icon: 'XIcon', label: '' }
        }
    },

    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },     
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getContracts()
            }
        },
        sortBy: {
            handler: function () {
                this.currentPage = 1
                this.getContracts()
            }
        },
        sortDesc: {
            handler: function () {
                this.currentPage = 1
                this.getContracts()
            }
        }
    }
}